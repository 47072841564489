<template>
  <div class="p-4">
    <b-field label="모노를 알게 된 경로" class="mb-5">
      <b-input v-model="path" disabled></b-input>
    </b-field>
    <b-field label="성함" class="mb-5">
      <b-input v-model="name" disabled></b-input>
    </b-field>
    <b-field label="연락처" class="mb-5">
      <b-input v-model="tel" disabled></b-input>
    </b-field>
    <b-field label="지역" class="mb-5">
      <b-input v-model="address" disabled></b-input>
    </b-field>
    <b-field label="우편번호" class="mb-5">
      <b-input v-model="postcode" disabled></b-input>
    </b-field>
    <b-field label="건물 유형" class="mb-5">
      <b-input v-model="buildType" disabled></b-input>
    </b-field>
    <b-field label="사용 용도" class="mb-5">
      <b-input v-model="usecase" disabled></b-input>
    </b-field>
    <b-field label="면적" class="mb-5">
      <b-input v-model="area" disabled></b-input>
    </b-field>
    <b-field label="공사 범위" class="mb-5">
      <b-input v-model="range" disabled></b-input>
    </b-field>
    <b-field label="공사희망일" class="mb-5">
      <b-input v-model="date" disabled></b-input>
    </b-field>
    <b-field label="예산" class="mb-5">
      <b-input v-model="budget" disabled></b-input>
    </b-field>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import client from '../api/client';
import { useRouter } from '@/use-router';

export default defineComponent({
  name: 'CounselingDetail',
  setup() {
    const token = ref('');
    const router = useRouter();
    const path = ref('');
    const name = ref('');
    const tel = ref('');
    const address = ref('');
    const postcode = ref('');
    const buildType = ref('');
    const usecase = ref('');
    const area = ref('');
    const range = ref('');
    const date = ref('');
    const budget = ref('');

    onMounted(async () => {
      token.value = router.currentRoute.params.token;
      const resp = await client.getCounselingDetail(token.value);
      path.value = resp.data.path;
      name.value = resp.data.name;
      tel.value = resp.data.tel;
      address.value = `${resp.data.address}, ${resp.data.detailAddress}`;
      postcode.value = resp.data.postcode;
      buildType.value = resp.data.buildType;
      usecase.value = resp.data.usecase;
      area.value = resp.data.area;
      range.value = resp.data.range;
      date.value = resp.data.date.split('T')[0];
      budget.value = resp.data.budget;
    });

    return {
      path,
      name,
      tel,
      address,
      postcode,
      buildType,
      usecase,
      area,
      range,
      date,
      budget,
    };
  },
});
</script>
